.main-form-container {

}

.df-header-setup-page {
  width: 570px;
  height: 320px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  /* border:1px solid black; */
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.alignmentTableonCategory {
  width: 90% !important;
  margin-left: 6rem !important;
}

.df-donation-form-setup-cards {
  width: 570px;
  height: 205px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}

.df-colors {
  width: 570px;
  height: 105px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-donation-amounts {
  width: 570px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
  padding-bottom: 20px;
}

.df-donation-embed {
  width: 570px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-setup-colorpicker {
  width: 100% !important;
  background: rgb(255, 255, 255);
  border: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  position: relative;
}

div.twitter-picker.df-setup-colorpicker > div:nth-child(1) {
  display: none !important;
}

.df-embed-colorpicker {
  background: rgb(255, 255, 255);
  border: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  position: relative;
  margin-bottom: 25px;
}
.hitInput > div div:nth-child(7) {
  position: absolute;
  top: 36px;
}
.hitInput > div > div > input {
  width: 75% !important;
  margin-top: 10px !important;
  margin-left: 30px;
}
.hitInput > div > div {
  margin-top: 10px !important;
}

div.twitter-picker.df-embed-colorpicker.hitInput.draw_btn_color > div:nth-child(1) {
  display: none !important;
}


.header-logo {
  width: 570px;
  height: 133px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.emb_card {
  width: 570px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 6px #f3f3f3;
  margin-top: 10px;
  padding: 20px;
}
.header-logo-2 {
  width: 570px;
  height: 230px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-header-accounts {
  width: 570px;
  height: 170px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-header-homepage {
  width: 570px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
  padding: 9px 0px;
}
.df-header-headline {
  width: 570px;
  height: 130px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}

.df-header-editor {
  width: 570px;
  height: 296px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}

.card_title{
  padding-bottom: 17px;
}

.twitter-picker.df-setup-colorpicker div {
  padding-left: 0 !important;
}

.df-form-title {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  text-transform: uppercase;
  font-size: 14px;
  padding: 0;
  margin: 0;

}
.df-donation-form-cards {
  margin-left: -8px;
}

#root > div > div > section > section > section > section > section > div.main-form-container > section > div:nth-child(1) > div.df-donation-form-cards > div:nth-child(1) > button > span.MuiButton-label > div > button > div {
  border: 1px solid #2A82F6;
}

/* #2A82F6 */

.Avatar .MuiCardContent-root {
  padding: 8px;
  border: 1px solid #D3D8DA;
  border-radius: 3px;
}

.selected-card .Avatar .MuiCardContent-root {
  padding: 8px;
  border: 1px solid #2A82F6;
  border-radius: 3px;
}

.df-form-link {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.df-form-link-2 {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  font-size: 14px;
  margin: 0;
}

.df-form-link-3 {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  font-size: 16px;
  padding: 15px 0 0px 0px;
  margin: 0;
}

.df-categories-dummy-text {
  color: var(--unnamed-color-818e94);
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0.4px;
  color: #818e94;
}
.df-categories-link-text {
  color: var(--unnamed-color-2a82f6);
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0.4px;
  color: #2a82f6;
}
.df-form-footer-link {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  font-size: 14px;
  margin: 0;
  font-weight: bold;
}
.df-form-btn {
  cursor: pointer;
  background: #f7f8fa 0% 0% no-repeat padding-box !;
  border: 1.5px dashed #e8e9e9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F7F8FA;
}
.df-dr-vd-dexc {
  color: var(--unnamed-color-4d4d4d);
  text-align: left;
  width: 260px;
  font: normal normal normal 14px/24px Roboto;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
}
.df-form-btn-img {
  border: 1.5px dashed #efefef;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.df-form-btn-img img {
  width: 200px;
  object-fit: contain;
  height: 60px;
}
.df-form-link-field {
  margin-left: -10px;
  margin-top: 5px;
}
.df-form-link-icon span {
  letter-spacing: 0.4px;
  color: #4d4d4d;
  opacity: 1;
  font-size: 12px;
  padding-left: 2px;
  padding-bottom: 2px;
}
.df-form-link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}
.df-form-link-field input {
  height: 0px;
  background: white;
}
.TabsButtonStyling .tab_header_button {
  margin-right: 45px;
}
.df-form-link-box {
  background-color: #f7f8fa !important;
  border-radius: 4px;
  position: relative;
  display: flex;
}

.df-form-link-box .MuiTooltip-tooltipArrow {
  padding: 10px !important;
  color: #ffffff ;
}

/* MuiTooltip-arrow */

.df-form-btn-text {
  text-align: center;
  text-transform: uppercase;
  color: #2a82f6;
  font-size: 14px;
  font-weight: 500;
}

.df-form-para {
  letter-spacing: 0.13px;
  color: #818e94;
  font-size: 14px;
  margin: 0;
  padding-top: 7px;
}
.df-form-subtitle-para {
  letter-spacing: 0.13px;
  color: #818e94;
  font-size: 14px;
  margin: 0;
}
.df-form-link-text {
  letter-spacing: 0.15px;
  background-color: #f7f8fa !important;
  color: #bcc0c2;
  font-size: 16px;
  margin: 0;
  padding: 14px 14px 16px 13px;
}
.df-form-link-text-field {
  letter-spacing: 0.15px;
  font-size: 16px;
  margin: 0;
  /* padding: 14px 0 16px 13px; */
}
.df-link-copy {
  letter-spacing: 1.25px;
  color: #2a82f6;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 3px;
  font-weight: 500;
}

.df-copy-div .MuiTooltip-popper .MuiTooltip-tooltip, 
.df-form-link-box .MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #818E94 !important;
  padding: 10px !important;
  position: relative !important;
}

.df-form-link-box .MuiTooltip-popper {
  left: 0px !important;
  top: -15px !important;
}

.df-copy-div .MuiTooltip-popper {
  left: -10px !important;
  top: -15px !important;
}

.df-copy-div .MuiTooltip-arrow,
.df-form-link-box .MuiTooltip-arrow {
  position: absolute !important;
  left: 50% !important;
  bottom: 1px !important;
-webkit-transform: translateX(-80%) !important;
transform: translateX(-80%) !important;
}

.df-homepage-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.df-home-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding-top: 10px
}


.df-homepage-btn  svg{
  position: relative;
  left: -4px;
  top: 2px;
}

.df-switch {
  padding-top: 0;
  padding-right: 0;
}

.df-text-field {

}

.df-header-headline .df-text-field {
  padding: 0 20px 0px 20px;
}

.df-donation-form-cards .DrawerCard .MuiPaper-outlined {
  width: 278px !important;
  padding-left: 0px !important;
  border: none;
}

.df-donation-form-cards .MuiPaper-outlined{
  border: none !important;
}


.df-donation-form-cards .MuiButton-text {
  
}

.df-form-para-amounts {
  letter-spacing: 0.15px;
  color: #4d4d4d;
  font-weight: bold;
  font-size: 16px;
}
.onActive {
  width: 121px;
  height: 46px;
  cursor: pointer;
  border: 2px solid var(--unnamed-color-2a82f6);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #2a82f6;
  border-radius: 4px;
  margin-right: 10px;
  opacity: 1;
}
.df-amount {
  width: 121px;
  height: 46px;
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e9e9;
  border-radius: 4px;
  margin-right: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.df-form-amount-box {
  display: flex;
  flex: 1;
  margin-left: -5px;
  margin-right: -5px;
}

.df-price {
  padding: 14px 0 13px 16px;
  margin: 0;
}

.df-divider {
  padding: 20px 0;
  padding-bottom: 10px;
}

.df-divider hr{
  width: auto !important;

}

.df-form-link-radio {
  display: flex;
}

.df-theme-color {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  margin-right: 10px;
}
.df-donation-form-colors {
  display: flex;
  padding-left: 20px;
}
.df-previewButton {
  width: 170px;
  height: 45px;
  background: #3cc480 0% 0% no-repeat padding-box;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.df-custom-color {
  width: 194px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e9e9;
  border-radius: 4px;
  display: flex;
}

.df-hash {
  width: 36px;
  height: 37px;
  background-color: rgb(240, 240, 240) !important;
  border: 1px solid #e8e9e9;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.df-custom-color .df-custom-color-input {
  width: 138px;
  height: 36px;
  border: none;
  border-bottom: 1px solid #e8e9e9;
  color: rgb(102, 102, 102) !important;
  font-size: 14px;
}

.twitter-picker.df-embed-colorpicker.hitInput.draw_btn_color input {
  height: 36px !important;
  width: 200px !important;
  padding-left: 20px !important;
  margin-left: 38px;
}

div.twitter-picker.df-embed-colorpicker.hitInput.draw_btn_color > div:nth-child(3) > div:nth-child(7) {
  height: 38px !important;
  font-size: 15px !important;
  width: 42px !important;
} 

.df-custom-color .df-hash-sign {
  margin: 0;
  align-self: center;
  background-color: rgb(240, 240, 240) !important;
  color: rgb(152, 161, 164) !important;
}

.df-img-text-field {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.df-img-text-field label{
  font-weight: 400;
}

.df-preview-btn {
  border-top: 1px solid #e8e9e9;
  border-bottom: 1px solid #e8e9e9;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px 0;
  margin: 20px 0;
}
.df-btn-preview-box {
  width: 170px;
  height: 45px;
  background: #3cc480 0% 0% no-repeat padding-box;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.df-btn-preview-text {
  color: white;
  text-transform: uppercase;
  text-align: center;
}

.df-code-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e9e9;
  border-radius: 4px;
  margin: 20px 0px;
}

.df-html-code p{
  line-height: 24px;
  font-size: 14px;
  color: #4D4D4D;
}

.df-html-code {
  padding: 20px 0px 35px 20px;
}

.df-copy-div {
  display: flex;
  justify-content: flex-end;
}
.df-donation-pop-btn {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.subHeaderDrawer {
  font-size: 14px !important;
}
/* .MuiPaper-outlined {
  border: none !important;
} */
.df-copy-text {
  letter-spacing: 1.25px;
  color: #4D4D4D;
  padding-right: 20px;
  text-transform: uppercase;
  cursor: pointer;
  display : block;
  position: relative;
  font-weight: 500;
}
.df-copy-text:after {
  content: "";
  background: #b2c7e4;
  display: block;
  position: absolute;
  padding-top: 24%;
  padding-left: 104%;
  margin-left: -1px !important;
  margin-top: -22%;
  opacity: 0;
  transition: all 0.5s;
}
.df-copy-text:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}
.df-upgrade-box {
  background: #edf6ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px;
}
.upgrade-text {
  letter-spacing: 0px;
  color: #4d4d4d;
  line-height: 1.6rem;
}

.df-upgrade-now {
  letter-spacing: 1.25px;
  color: #2a82f6;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

/* .df-text-field .text-editor div:nth-child(1) {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
} */
.df-form-box {
  width: 570px;
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-form-para.note {
  letter-spacing: 0.4px;
  color: #b6b9b9;
  opacity: 1;
  font-size: 12px;
  margin-top: 6px;
}

.file_name{
  font-size: 16px;
  color: #4D4D4D;
  letter-spacing: 0.15px
}

.emb_card .MuiOutlinedInput-notchedOutline {
  border-color: #E8E9E9;
}

.min_donaton{
  margin-top: 15px;
}

.draw_btn_color div{
  padding: 0 !important;
}

.step_card_fild.df-donation-form-cards .DrawerCard .MuiPaper-outlined {
  width: 278px !important;
  padding-left: 0px !important;
  border: none !important;
}

.df-donation-form-cards .Avatar .MuiCardContent-root {
  padding: 8px;
  width: 278px;
}
.df-donation-form-cards{
  width: 105%;
}

.df-donation-form-cards .MuiCardActionArea-root {
  width: 100%;
  display: inline-flex;
  text-align: inherit;
}

.tab_header_button {
  right: 80px !important;
  margin-top: 3px !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #3CC480 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #3CC480 !important;
}

.CopiedTooltip{
  display: none;
}

/* p.df-link-copy:focus .CopiedTooltip{
  display: block;
  position: absolute;
} */
