.main-footer{
  
}
#main-footer-border{
  border-top: 1px solid rgba(129, 142, 148, 0.3) !important;
}

.footer-secured {
  text-align: left;
  letter-spacing: 0.4px;
  color: #818e94;
  text-transform: uppercase;
  font-size: 12px;
  flex: 1;
  opacity: 0.7;
}

.footer-terms {
  text-align: right;
  letter-spacing: 0.4px;
  color: #818e94;
  text-transform: uppercase;
  font-size: 12px;
  margin-right: 20px;
  opacity: 0.7;
  cursor: pointer;
}

.footer-terms a {
  color: #818e94;
  text-decoration: none;
}

.footer-copyright {
  text-align: right;
  letter-spacing: 0.4px;
  color: #818e94;
  text-transform: uppercase;
  font-size: 12px;
  opacity: 0.7;
}
.main-footer svg{
  margin-bottom: 0px !important;
}



