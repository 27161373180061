.CheckoutPaymentForm{
    border:#E4E8EC 1px solid;
    padding: 32px;
    border-radius: 4px;
}

.formSectionTitle{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color:#818E94;
    border-bottom:#E4E8EC 1px solid;
    padding-bottom:8px;
    text-transform: uppercase;
}

.mainTextHeading {
    color: #4D4D4D;
    font-size: 24px;
    margin-bottom: 5px;
}

.billing-info-wrapper .inner-form {
    width: 770px !important;
}

.separator_line{
    background-color: #E4E8EC;
    height: 1px;
}

.CheckoutPaymentForm .form_button_box{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

.tos_text{
    color: #4C5E67;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.tos_text a{
    color: #0590FA;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.billingAddressContainer .billingAddressTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.billingAddressContainer .billingAddressTitle span{
    color: #4C5E67;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.billingAddressContainer .billingAddressTitle a{
    color: #0590FA;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.billingaddressInfo li{
    display: block;
    color: #4C5E67;
    font-size: 16px;
    margin-top:4px;
    letter-spacing: 0px;

}


.masterCardImage {
    width: 40px !important;
    left: 5;
    position: 'relative';
}

/* .ReactFlagsSelect-module_selectBtn__19wW7 {
    font-size: 16px;
    margin-top: 0px !important;
    height: 56px;
} */

input {
    outline:none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
   margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.MuiList-root.MuiMenu-list .MuiListItem-gutters {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.form_button_Contianer .CheckoutRemovebutton .MuiButton-root {
    background-color: transparent !important;
}