.loading-para {
  color: var(--unnamed-color-818e94);
  text-align: center;
  letter-spacing: 0.48px;
  color: #818e94;
  margin-top: 40px;
}

.quote-div {
  margin-top: 137px;
}

.quote-text {
 
  text-align: center;
  letter-spacing: 0px;
  color: #4d4d4d;
  text-transform: capitalize;
  font-size: 28px;
}
.quote-book {
 
text-align: center;
font-size: 16px;
letter-spacing: 0px;
color: #818E94;
}