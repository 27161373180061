/* .footer{
	text-align: center;
} */

.btm_ftr_left {
    margin-top: 18px;
    width: 50%;
    float: left;
}

.copyright_links {
    text-align: right;
    color: #6f7d84;
    font-size: 14px;
    font-weight: 400;
    padding-top: 20px;
    float: right;
}
/* 
.footer {
    background: #f7f8fa;
    padding: 60px;
} */

.ftr_btm {
    background: #f7f8fa;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: #d1d7d9 1px solid;
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* .footer p {
    font-size: 18px;
    color: #4C5E67;
    max-width: 824px;
    line-height: 35px;
    letter-spacing: 1px;
    display: block;
    margin: 0 auto;
} */

.copyright_links img {
    width: 133px;
    margin-left: 10px;
}

.ftr_logo {
    max-width: 255px;
    margin-bottom: 20px;
}

.btm_ftr_left p {
    color: #4C5E67;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
.btm_ftr_left a {
    color: #4C5E67;
    font-size: 12px;
    font-weight: 400;
}

.copyright_links {
    text-align: right;
    color: #6f7d84;
    font-size: 14px;
    font-weight: 400;
    padding-top: 20px;
    float: right;
}

@media (max-width:990px){
    .btm_ftr_left {
        margin-top: 18px;
        width: 100%;
        float: none;
        text-align: center;
    }

    .copyright_links {
        text-align: center;
        color: #6f7d84;
        font-size: 14px;
        font-weight: 400;
        padding-top: 20px;
        float: none;
        width: 100%;
    }

    .ftr_btm {
        position:relative;
    }
}