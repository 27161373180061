.org-accordian .sub-heading svg {
  fill: #fff;
  background: #2a82f6;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
}

.org-accordian .sub-heading {
  display: flex;
  align-items: center;
}

.org-accordian .MuiAccordionSummary-content {
  align-items: center;
}

.org-accordian .MuiIconButton-label {
  color: #2a82f6;
  font-size: 14px;
  font-weight: 500;
}

.org-accordian .MuiAccordionSummary-expandIcon {
  transform: rotate(0deg) !important;
}

.org-accordian-single-tab.Mui-expanded .MuiAccordionSummary-root {
  display: block;
}

.org-accordian-single-tab.Mui-expanded
  .MuiAccordionSummary-content.Mui-expanded {
  display: block;
}

.org-accordian-single-tab.Mui-expanded
  .MuiAccordionSummary-content.Mui-expanded
  .sub-heading {
  display: none;
}

.org-accordian-single-tab.Mui-expanded .MuiAccordionSummary-expandIcon {
  display: none;
}



.subtable
  .org-accordian
  .org-accordian-single-tab.Mui-expanded
  p.MuiTypography-body1 {
  position: relative;
  top: 10px;
}

.org-accordian .cloud-icon svg {
  fill: #2a82f6;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
}

.second-row .photo-icon {
  background: #2a82f6;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.org-accordian .photo-icon svg {
  fill: #fff;
  color: #fff;
  font-size: 28px;
}

.second-row {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
}

.second-row .photo-icon {
  line-height: 10px;
}

.second-row .logo-field {
  color: #2a82f6;
  border: 1px solid #e4e8ec;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.second-row .cloud-icon {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.second-row .logo-label {
  color: #818e94;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 6px;
}

.second-row .first-field {
  width: 49%;
}

.second-row .upload-field {
  width: 100%;
}

.org-accordian .org-accordian-single-tab .summary-accordian-divider {
  width: 133.5%;
  position: absolute;
  right: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.org-accordian .org-accordian-single-tab .summary-accordian-actions {
  position: absolute;
  right: 0;
  margin-top: 15px;
}

.org-accordian
  .org-accordian-single-tab
  .summary-accordian-actions
  .cancel-btn {
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.org-accordian .org-accordian-single-tab .sub-heading.link {
  color: #818e94;
}

.org-accordian .org-accordian-single-tab .linkBoxContainer {
  background-color: #f8f8f8;
  margin-left: 0;
  margin-right: 0;
  padding: 13px 10px;
  display: flex;
  color: #bcc0c2;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  margin-top: 15px;
  width: 60%;
  align-items: center;
}

.org-accordian .org-accordian-single-tab .linkBoxContent {
  display: flex;
  align-items: center;
}

.org-accordian .org-accordian-single-tab .linkBoxContent .linkAvailable {
  color: #4d4d4d;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}

.org-accordian
  .org-accordian-single-tab
  .project-page-details
  .projectPageText1 {
  color: #818e94;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  top: 0px !important;
  width: 65%;
}

.org-accordian
  .org-accordian-single-tab
  .project-page-details
  .projectPageText2 {
  color: #818e94;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  top: 0px !important;
}

.org-accordian
  .org-accordian-single-tab
  .linkBoxContainer
  .input_container.textfield {
  margin-left: 8px;
}

.org-accordian
  .org-accordian-single-tab
  .linkBoxContainer
  .input_container.textfield
  input {
  height: 36px;
}

.org-accordian .org-accordian-single-tab .project-page-details {
  display: block;
}

.org-accordian .org-accordian-single-tab .summary-accordian-actions .save-btn {
  color: #2490f9;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.org-accordian
  .org-accordian-single-tab
  .second-row
  .first-field
  .logo-field
  input {
  border-radius: 50%;
  width: 36px;
  overflow: hidden;
  height: 36px;
  margin-right: 3px;
}

.org-accordian
  .org-accordian-single-tab
  .second-row
  .first-field
  .logo-field
  input::-webkit-color-swatch-wrapper {
  margin: 0 auto;
  padding: 0;
  border-radius: 50px;
  width: 50px;
  position: relative;
  left: -5px;
  height: 40px;
  bottom: 2px;
}

.org-accordian
  .org-accordian-single-tab
  .second-row
  .first-field
  .logo-field
  .colorCode {
  color: #4d4d4d;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .mainbannerPara {
  background-color: #f9ebed;
  color: #c44058;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  padding: 5px 5px;
  width: 60px;
  font-weight: bold;
  border-radius: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.AddedChip {
  background-color: #effbf5;
  color: #3cc480;
  font-size: 12px;
  padding: 5px 5px;
  font-weight: bold;
  border-radius: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  max-width: 68px;
  align-items: center;
}

.AddedChip svg {
  font-size: 17px;
  margin-right: 2px;
}
.infoIcon {
  color: #d3d8da;
  margin-left: 8px;
}

.org-accordian .org-accordian-single-tab .pageTitleContent {
  width: 80%;
  display: block;
}

.org-accordian .org-accordian-single-tab .pageTitleContent .mainBannerInput {
  width: 100%;
}

.org-accordian .org-accordian-single-tab .supportAttention {
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  top: 0px !important;
  margin-top: 18px;
  font-weight: bold;
}

.org-accordian .org-accordian-single-tab ul {
  padding-left: 16px;
  margin-top: 0;
}

.org-accordian .org-accordian-single-tab ul li {
  color: #818e94;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .org-accordian-single-tab ul li::before {
  content: "\2022";
  color: #2a8853;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.org-accordian .org-accordian-single-tab .mainPageContent {
  display: block;
  width: 80%;
}

.org-accordian .org-accordian-single-tab .mainPageContent .photo-icon {
  line-height: 1;
}

.org-accordian .org-accordian-single-tab .mainPageContent .cloud-icon {
  display: flex;
  align-items: center;
}

.org-accordian .org-accordian-single-tab .mainPageContent .mainPagePictureText {
  color: #818e94;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .org-accordian-single-tab .mainPageContent .logo-field {
  color: #2a82f6;
  border: 1px solid #e4e8ec;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer !important;
}

.org-accordian .org-accordian-single-tab .donationPageLinkT {
  width: 80%;
}

.org-accordian .org-accordian-single-tab .donationText {
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.org-accordian .org-accordian-single-tab .projectPageText4 {
  color: #818e94;
  font-size: 14px;
  margin-top: 30px;
  width: 80%;
}

.org-accordian .org-accordian-single-tab .projectPageText4 {
  color: #818e94;
  font-size: 14px;
  margin-top: 5px;
  width: 80%;
}

.org-accordian .org-accordian-single-tab .amountInput {
  width: 30%;
  margin-top: 20px;
}

.org-accordian .org-accordian-single-tab .amountInput1 {
  width: 68%;
  margin-top: 20px;
  margin-left: 5px;
}

.org-accordian .org-accordian-single-tab .amountInput p {
  top: 0px !important;
}

.org-accordian .org-accordian-single-tab .amountLabel {
  color: #818e94;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .org-accordian-single-tab .priceLabelSelect {
  display: flex;
  width: 80%;
  align-items: flex-end;
}

.org-accordian .org-accordian-single-tab .priceLabelSelect .delete-icon svg {
  margin: 0 7px;
  color: #859198;
}

.org-accordian .org-accordian-single-tab .priceLabelSelect .add-icon svg {
  margin: 0 7px;
  color: green;
}

.add-approach-icon {
  top: -5px;
}

.add-approach-icon svg {
  color: green;
}

.org-accordian .org-accordian-single-tab p.makeStyles-heading-2 {
  max-width: 240px;
}

.delete-logo-icon svg {
  fill: #859198;
  color: #859198;
  position: relative;
  top: 3px;
  cursor: pointer;
}

.uploaded-img {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  object-fit: cover;
}

.uploaded-img-container {
  color: #4d4d4d;
  display: flex;
  overflow-x: hidden;
  align-items: center;
  margin-right: 10px;
}

.uploaded-img-container img {
  margin-right: 10px;
}

.sub-heading .phase-icon svg {
  fill: #2a82f6;
  background: transparent;
  padding: 2px;
  border-radius: 50%;
  margin-right: 4px;
  margin-top: 2px;
}

.sub-heading .visibility-section-icon svg {
  fill: #00000033;
  background: transparent;
  padding: 0px;
  border-radius: 50%;
  margin-right: 4px;
  margin-top: 2px;
}

.linkAvailable .available-icon svg {
  position: relative;
  top: 7px;
  fill: green;
}

.linkAvailable .not-available-icon svg {
  position: relative;
  top: 7px;
  fill: red;
}

.org-accordian
  .org-accordian-single-tab
  .MuiAccordionSummary-content
  p:first-child {
  width: 220px;
  max-width: 220px;
  display: flex;
  align-items: center;
  color: #4d4d4d;
}

.org-accordian
  .org-accordian-single-tab
  .amountInput
  .MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart {
  padding-left: 0px !important;
}

.org-accordian
  .org-accordian-single-tab
  .amountInput
  .MuiInputAdornment-root.MuiInputAdornment-positionStart
  p {
  height: 56px;
  line-height: 56px;
  width: 45px;
  text-align: center;
  border-right: 1px solid #e4e8ec;
  font-size: 16px;
}

.disableBtnItem {
  color: lightgray;
}

.logo-field .upload-logo-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo-field .upload-color-wrapper {
  display: flex;
  align-items: center;
}

.MuiMenu-list .MuiListItem-button {
  padding-left: 10px !important;
}

.donation-approach-label {
  color: #818e94;
  font-size: 12px;
}

.priceLabelSelect svg {
  cursor: pointer;
}
