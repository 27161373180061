.signInClass {
    width: 100% !important;
    margin: auto;
    border: unset;
    background-image: url('../../assets//images/khushbo_background.png');

}

.signInClass div div div{
    border:none !important;
    background-repeat: no-repeat;  
}
 
.signInClass div div div svg{
    top: 0;
}

.signInClass section h1 {
    font-family: Roboto !important;
    color:#5A5A5A !important;
    font-weight: 400;
    font-size: 30px !important;
}

main section form section div:nth-child(2) label span:nth-child(2) p {
    font-family: Roboto !important;
    color:#D3D8DA !important;
    font-size: 14px !important;
}
