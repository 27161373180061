.loader_bg {
    background: #000;
    padding: 20px;
    margin-top: 80px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
    margin: 0;
    z-index: 999;
}


.loader {
    color: #ffffff;
    display: inline-block;
    margin: 0;
    text-align: center;
    position: fixed;
    width: 100%;
    top: 40%;
    z-index: 999;
}

.loader p{
    color: #fff;
    font-size: 18px;
    padding: 20px;
    font-weight: 300;
}