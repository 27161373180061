
 .stripe-para {
  font-size: 16px;
  color: #818e94;
  letter-spacing: 0.48px;
  text-align: center;
  line-height: 25px;
  margin: 0;
}

.strip_title{
  letter-spacing: 0.48px;
  color: #4D4D4D;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 21px;
  text-align: center;
}

.setup-footer {
  margin-left: auto;
  position: fixed;
  bottom: 0;
  left: 0%;
  width: 100%;
}

.MuiStepIcon-root {
  font-size: 32px !important;
}

.stripe-account {
  text-align: center;
  padding-bottom: 10px;
}

.stripe-skip-link {
  display: inline-block;
  background: #F7F8FA;
  border: #E8E9E9 1px solid;
  padding: 12px 28px;
  text-decoration: none;
  color: #4D4D4D;
  font-weight: 500;
  border-radius: 4px;
}

.creatButn{
  text-align: center;
  margin-top: 10px;
}

.stripe-skip{
  text-align: center;
  margin: 0;
}

.stripe-skip a {
  text-align: center;
  text-transform: uppercase;
  color: #818E94;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}

