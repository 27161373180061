@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.SubsFormContainer{
    background:#F7F8FA;
    width: 100%;
    height: 100%;
    position: absolute;
    padding-top: 50px;
    padding-bottom: 50px;

}

.SubsFormContainer .QDCLogo{
    height: 50px;
    margin-bottom: 20px;
}
.SubsFormContainer .QDCLogo img{
   object-fit: contain;
   width: 100%;
   height: 100%;
}

.SubsFormBody{
    background: #fff;
    max-width: 600px;
    position: relative;
    margin: 0 auto;
    padding: 40px;

}

.SubsFormBody .SubsFormTitle{
    font-size: 18px;
    color: #444444;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    margin-bottom: 20px;
}

.SubsFormBody h3{
    font-size: 16px;
    color: #444444;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    line-height: 26px;
    margin-bottom: 30px;
    margin-top: 0;
}

.SubsFormBody p{
    font-size: 14px;
    color: #444444;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    line-height: 26px;
    margin-top: 0;
}

.SubsFormBody .SubsFormField{
    margin-top: 30px;
}

.SubsFormBody .SubsFormButton{
    margin-top: 30px;
}

.QDCFooterLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.QDCFooterLogo span{
    color: #A5A5A5;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 28px;
}

.QDCFooterLogo img{
    width:146px;
    margin-left: 4px;
}

.SubsFormBody .outlineButton .MuiButton-containedPrimary{
    background-color: #fff !important;
    border: #E4E8EC 1px solid;

}

.SubsFormBody .outlineButton .MuiButton-containedPrimary .button_text {
     color: #2490F9 !important;
}

.SubsFormBody .MuiButton-root{
    padding: 6px 16px !important;
}

.SubsFormBody .TextArea{
    margin-top: 30px;
}
.SubsFormBody .TextArea .lowerEditorService{
    position: absolute;
    right: 40px;
}